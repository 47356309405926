<script>
  export let article;
  export let closeArtistPage;

  import WikiSection from "./WikiSection.svelte";
  import WikiHeader from "./WikiHeader.svelte";
  import Portal from "svelte-portal";
  import WikiLogo from "../assets/wikipedia_logo.png";
  import WikiLogoMobil from "../assets/wikipedia_logo_mobil.png";
  import VimeoVideo from "./VimeoVideo.svelte";
  import Stefano from "./Stefano.svelte";
  import Laurin from "./Laurin.svelte";
  import Chiara from "./Chiara.svelte";
  import IFrame from "./IFrame.svelte";
  import Img from "./Img.svelte";

  let showFullscreen = false;
  let hasTranslation = article.hasTranslation;
  let language = "de";

  function handleScroll(target) {
    const targetElem = document.querySelector(`.${target}`);
    const targetOffset = targetElem.offsetTop;
    window.scrollTo({
      top: targetOffset,
      behavior: "smooth",
    });
  }

  function openFullscreen() {
    showFullscreen = true;
  }

  function closeFullscreen() {
    showFullscreen = false;
  }
</script>

<div class="wiki">
  <div class="bar-left">
    <!-- svelte-ignore a11y-missing-attribute -->
    <img src={WikiLogo} class="wiki-logo" />
    <!-- svelte-ignore a11y-missing-attribute -->
    <img src={WikiLogoMobil} class="wiki-logo-mobil" />

    <br />
    <!-- svelte-ignore a11y-missing-attribute -->
    <div class="links-left font-small">
      <!-- svelte-ignore a11y-missing-attribute -->
      <a on:click={closeArtistPage} class="link">Hauptseite</a>
      <!-- svelte-ignore a11y-missing-attribute -->
      <a on:click={closeArtistPage} class="link">Themenportale</a>
      <!-- svelte-ignore a11y-missing-attribute -->
      <a on:click={closeArtistPage} class="link">Zufällige Artikel</a>
      <br />
      <p class="text-devider">Artikel verbessern</p>
      <!-- svelte-ignore a11y-missing-attribute -->
      <a on:click={closeArtistPage} class="link">Neue Artikel anlegen</a>
      <!-- svelte-ignore a11y-missing-attribute -->
      <a on:click={closeArtistPage} class="link">Autorenportal</a>
      <a on:click={closeArtistPage} class="link">Hilfe</a>
      <!-- svelte-ignore a11y-missing-attribute -->
      <a on:click={closeArtistPage} class="link">Letzte Änderungen</a>
      <a on:click={closeArtistPage} class="link">Kontakt</a>
      <a on:click={closeArtistPage} class="link">Spenden</a>
      <br />
      <p class="text-devider">Werkzeuge</p>
      <a on:click={closeArtistPage} class="link">Links auf dieser Seite</a>
      <a on:click={closeArtistPage} class="link"
        >Änderungen an verlinkten Seiten</a
      >
      <a on:click={closeArtistPage} class="link">Spezialseiten</a>
      <a on:click={closeArtistPage} class="link">Permanenter Link</a>
      <a on:click={closeArtistPage} class="link">Seiteninformationen</a>
      <br />
      <p class="text-devider">Drucken / Exportieren</p>
      <a on:click={closeArtistPage} class="link">Als PDF herunterladen</a>
      <!-- svelte-ignore a11y-invalid-attribute -->
      <a on:click={closeArtistPage} class="link">Druckversion</a>
      {#if hasTranslation}
        <a
          on:click={() => (language = language === "de" ? "en" : "de")}
          class="link">Übersetzung</a
        >
      {/if}
    </div>
  </div>

  <div class="bar-right">
    <div class="bar-top">
      <div class="buttons-top-left">
        <div class="button-active">
          <p class="button-top">Artikel</p>
        </div>
        <div class="button-inactive">
          <p class="button-top">Diskussion</p>
        </div>
      </div>
      <div class="buttons-top-right">
        <div class="button-active">
          <p class="button-top">Lesen</p>
        </div>
        <div class="button-inactive">
          <p class="button-top">Quelltext bearbeiten</p>
        </div>
        <div class="button-inactive">
          <p class="button-top">Versionsgeschichte</p>
        </div>
      </div>
    </div>
    <div class="article">
      <!-- Header -->
      <WikiHeader
        title={article.title}
        abstract={article.abstract}
        {closeArtistPage}
      />

      <!-- Inhaltsverzeichnis -->
      <div class="inhaltsverzeichnis">
        <p class="font-medium bold">Inhaltsverzeichnis</p>
        {#each article.sections as section}
          {#if hasTranslation}
            <span
              on:click={() => handleScroll(section[language].classname)}
              class={`font-medium link ${
                section[language].isSubSection && "subsection"
              }`}>{section[language].title}</span
            >
          {:else}
            <span
              on:click={() => handleScroll(section.classname)}
              class={`font-medium link ${section.isSubSection && "subsection"}`}
              >{section.title}</span
            >
          {/if}
        {/each}
      </div>

      {#if showFullscreen}
        <Portal target="body">
          <div class="fullscreenImgContainer">
            <span on:click={closeFullscreen} class="close-icon">
              <button>[schliessen]</button>
            </span>
            {#if article.artwork.type === "vimeo"}
              <VimeoVideo link={article.artwork.link} />
            {:else if article.artwork.type === "img"}
              <Img src={article.artwork.src} />
            {:else if article.artwork.type === "iframe"}
              <IFrame link={article.artwork.link} />
            {:else if article.artwork.type === "stefano"}
              <Stefano />
            {:else if article.artwork.type === "laurin"}
              <Laurin />
            {:else if article.artwork.type === "chiara"}
              <Chiara />
            {/if}
          </div>
        </Portal>
      {/if}

      {#each article.sections as section}
        {#if hasTranslation}
          <WikiSection section={section[language]} {openFullscreen} />
        {:else}
          <WikiSection {section} {openFullscreen} />
        {/if}
      {/each}
    </div>
    <br />
    <p class="font-small footer">
      Diese Seite wurde zuletzt am 24. Juni 2022 um 19:00 Uhr bearbeitet.
    </p>
    <br />
  </div>
</div>

<style>
  :global(p) {
    margin: 0;
  }

  .wiki {
    width: 100vw;
    left: 0;
    top: 0;
    /* position: fixed; */
    display: flex;
    flex-direction: row;
    padding: 8px 0px;
    background: #f8f9fa;
    scroll-behavior: smooth;
    cursor: default;
  }
  .bar-left {
    padding: 0 14px;
    display: flex;
    flex-direction: column;
    width: 200px;
    left: 0;
  }

  .bar-right {
    display: flex;
    flex-direction: column;
  }

  .bar-top {
    z-index: 100;
    float: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    list-style: none none;
    margin: 0;
    padding-top: 40px !important;
    margin-bottom: -1px;
    /* background-color: #fbfbfb;
        background-image: linear-gradient(to bottom, #ffffff 50%, #f6f6f6 100%);
        background-position: bottom left; */
  }
  .buttons-top-right {
    float: right;
    display: flex;
    flex-direction: row;
    height: 100%;
    list-style: none none;
    margin: 0;
    padding: 0;
    margin-right: 20%;
    background-position: left bottom;
    background-image: linear-gradient(
      to bottom,
      rgba(167, 215, 249, 0) 0,
      #a7d7f9 100%
    );
    background-repeat: no-repeat;
    background-size: 1px 100%;
    padding-left: 1px;
  }

  .buttons-top-left {
    float: left;
    display: flex;
    flex-direction: row;
    height: 100%;
    list-style: none none;
    margin: 0;
    padding: 0;
    background-position: left bottom;
    background-image: linear-gradient(
      to bottom,
      rgba(167, 215, 249, 0) 0,
      #a7d7f9 100%
    );
    background-repeat: no-repeat;
    background-size: 1px 100%;
    padding-left: 1px;
  }

  .button-active {
    color: #202122;
    background-image: linear-gradient(to top, #ffffff 0, #f8f9fa 100%);
    background-position: left bottom;
    background-repeat: repeat-x;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  .button-inactive {
    background-image: linear-gradient(
      to top,
      #77c1f6 0,
      #e8f2f8 1px,
      #f8f9fa 100%
    );
    background-position: left bottom;
    background-repeat: repeat-x;
    height: 100%;
    margin: 0;
    padding: 0;
    color: #0645ad;
  }

  .button-top {
    box-sizing: border-box;
    display: block;
    float: left;
    height: 3.07692308em;
    position: relative;
    padding-top: 1.25em;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 0.8125em;
    cursor: pointer;
    background-position: right bottom;
    background-image: linear-gradient(
      to bottom,
      rgba(167, 215, 249, 0) 0,
      #a7d7f9 100%
    );
    background-repeat: no-repeat;
    background-size: 1px 100%;
  }

  .wiki-logo {
    width: 135px;
    display: block;
  }

  .wiki-logo-mobil {
    width: 119px;
    display: none;
  }

  .links-left {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0 8px;
  }

  .wiki-big {
    background-color: red;
    width: 90vw;
  }

  .article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    color: black;
    padding: 15px 20px 20px 20px;
    background: #ffffff;
    border: 1px solid #a9d8f8;
  }

  .bold {
    font-weight: 700;
  }

  .inhaltsverzeichnis {
    padding: 8px 12px;
    background: #f8f9fa;
    border: 1px solid #a2a9b1;
    width: auto;
    display: table;
    line-height: 1.6;
  }

  a {
    color: #0e49aa !important;
  }

  .link {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    color: #0e49aa;
  }

  .link:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .text-devider {
    width: 100%;
    padding-bottom: 3px;
    color: #54595d;
    background-image: linear-gradient(
      to right,
      rgba(200, 204, 209, 0) 0,
      #c8ccd1 33%,
      #c8ccd1 66%,
      rgba(200, 204, 209, 0) 100%
    );
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% 1px;
  }

  .fullscreenImgContainer {
    position: fixed;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background-color: #ffffffd5;
    z-index: 920;

    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  .close-icon {
    position: fixed;
    z-index: 450;
    right: 10px;
    top: 10px;
    mix-blend-mode: exclusion;
  }

  .subsection {
    margin-left: 0.5rem;
  }

  @media only screen and (max-width: 768px) {
    .footer {
      padding: 0.5rem;
      padding: 0 16px;
    }

    .wiki {
      flex-direction: column;
    }

    .article {
      border: none;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    }

    .links-left {
      display: none;
    }

    .wiki-logo {
      display: none;
    }

    .wiki-logo-mobil {
      display: block;
    }

    .bar-top {
      display: none;
    }

    .bar-left {
      max-width: 100%;
      padding: 0 16px;
      margin-top: 1em;
    }
  }
</style>
