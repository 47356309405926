<script>
    export let link;
</script>

<style>
    iframe {
        width: 100vw;
        height: 100vh;

        z-index: 80;
        position: absolute;
        opacity: 100;
        left: 0;
        top: 0;
    }
</style>

<!-- svelte-ignore a11y-missing-attribute -->
<iframe src={link} frameborder="0" allowfullscreen />
