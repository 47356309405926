<script>
</script>

<div class="text_container">
  <div class="scrollable_area_left scrollable_text">
    <div class="fade_in" />
    Wenn man ihnen so zuhört, kann durchaus die Frage aufkommen, ob ihnen bewusst
    ist, dass es nicht nach ganz oben reichen wird. Dass ihnen die, die sie für unaussprechlich
    anders halten, mitunter näher sind, als der Kreis der wirklich Besitzenden. Empfindlich
    und nervös für noch so kleine Gesten ihrer Vorgesetzten, für alle Spuren, die
    nur den Schluss zulassen, dass das Ende der Leiter erreicht und alles weitere
    nur über den Helikopterlandeplatz zu erreichen ist, werden sie mühelos, dabei
    wahrscheinlich unbeabsichtigt, zumindest ohne bewussten Plan, in Schach gehalten.
    So pocht man, ängstlich zum Abgrund schielend, auf den tugendhaften Unterschied
    zu allem, was auf dem Weg nach unten herumlungert. Die anderen - die unbelehrbaren.
    Einem verdorbenen Geschmack folgend, stets die falsche Entscheidung treffend,
    der Klotz am Bein all jener, die aufgehört haben, Arbeiter zu sein. Ob die Umstände
    das Bewusstsein bedingen oder andersherum, warum sich diese Frage stellen, haben
    sie doch Zugriff auf die Wahrscheinlichkeiten, man muss ja nur schauen, wer wen
    wählt und so weiter. So empfinden sie, lautstark, leisten täglich Renovierungsarbeit,
    um der Einheit im Kleinen willen. Eine nervöse Einheit, die feinen Gräben in
    den eigenen Reihen nur kurzfristig kittend, denn: Serviceverbesserungen - ja,
    eine moralische Verfehlung, schlechten Geschmack erkennen sie gestochen scharf
    - einen zusammenhängenden Wunsch zu formulieren hingegen, undenkbar. Grundlegend,
    für sie: Wir befinden uns am stets steigenden Zenit der menschlichen Entwicklung.
    Eine abrufbare state-of-the-art-Moral, für deren Erwerb sind allein ein tugendhafter
    Wille und eine gewisse Grundintelligenz vonnöten. Beängstigend dagegen: Die Vorstellung,
    dass sich Umstände und Überzeugungen nicht auf einen sinnhaften Endpunkt zuspitzen,
    dass die Vernachlässigung basaler Bedürfnisse zu Artikelüberschriften führen
    kann, die als dem Zählstand des Zeitstrahls unangemessen abzutun sind. Dadurch
    der Eindruck von mehreren Zeitsphären: Manche, darunter die eigene, auf der Höhe
    der Zeit, aber darum natürlich nie stillstehend, stets in Weiterentwicklung begriffen.
    Andere wiederum in verschiedenen Stadien der Unterentwicklung gefangen. Ihnen
    wirkt dadurch, wie mit einem mulmigen Gefühl bei Gelegenheit bewusst wird, obwohl
    man ja durch tägliche Nachrichten ihrer Existenz versichert wird, das Leben in
    den antiquierten Sphären ganz und gar unwirklich. Eine Fiktion, da doch nur wirklich,
    und darum lebenswert, sein kann, was nicht bloß in der wiederholten Übung zum
    Totschlagen der Lebenszeit rotiert, sondern vorwärts schraubt. Wie kann im Jahr
    2022 noch?... Das ist doch einfach.
    <div class="fade_out" />
  </div>

  <div class="scrollable_area_right scrollable_text">
    <div class="fade_in" />
    Unser*e Held*in, die* das Dazwischen zur Handlungsfläche im Namen geschichtlicher
    Kontinuität werden lässt, glaubt in der Überwindung einen Akt von Diskontinuität
    in Form einer Schließung zu vollziehen. Tatsächlich bleibt die Heldentat jedoch
    dem Bestehen gewidmet. Der* Held* greift ein, aber nimmt den Lauf der Dinge vorweg,
    denn das Dazwischen wird zur Fläche für das aus der Vergangenheit abgeleitete
    projektive Handeln. Die mögliche Überforderung Held*in zu sein kann dabei jedoch
    die Überwältigung des Ereignisses zulassen. Diese* überforderte* Held*in endet
    dabei nicht in einem Anti-Heldentum oder in anderen Formen der Überwindung. Mit
    der Überforderung zerfällt das Heldentum in allen Deklinationen. Der Zweck der
    Held*in scheint dabei in der Identität zu bestehen, die sich der Kausalität verschrieben
    hat. Die Überwindung, die Revolution bzw. die Heldentat funktionieren nicht,
    weil das Geflecht zu dicht ist. Sie funktioniert nicht, weil wir in das Netz
    unserer gegenseitigen Erwartungen eingebunden sind, das sich der Eroberung der
    Akteur*in nicht einfügen kann. Gerade weil wir voneinander abhängig sind, kann
    die Eroberung nicht funktionieren. Die Vorstellung von Veränderung kann jedenfalls
    nicht mehr in Überwindung bestehen.Handeln bedeutet nicht Überwinden, sowie Überwindung
    nicht zur Handlungsschablone werden sollte. Wenn das Ereignis nicht gedacht werden
    kann, sondern das Ereignis das Denken konstituiert, lässt sich nur mit dem Ereignis
    handeln und nicht ohne. Mit der Abkehr von kausal-linearer Geschichte zerfällt
    sowohl der Held als auch der Anti-Held. Alternative Formen des Handelns können
    sich damit sowohl von einem Verständnis der Zeit als ewige Wiederkehr der Geschichte,
    als auch von einem linearen Fortschreiten oder dem Streben der Geschichte in
    eine Richtung überhaupt absetzen. All jene Vorstellungen unterstellen der Veränderung
    als Zeit besondere Mechanismen, die sich als einfache Kausalbeziehungen darstellen
    lassen sollen. Stattdessen öffnet alternatives Handeln als rekursive Konfrontation
    mit der historischen Gegenwart die Möglichkeit eines die Immanenz der Dinge anerkennenden
    Handelns.
    <div class="fade_out" />
  </div>
  <audio autoplay loop>
    <source src="laurin_audio.mp3" type="audio/mpeg" />
    Your browser does not support the
    <code>audio</code>
    element.
  </audio>
</div>

<style>
  .scrollable_text {
    font-family: sutro, serif;
    font-weight: 300;
    font-style: normal;
    font-size: 2em;
    line-height: 1.3em;
    letter-spacing: 0.5px;
    height: 100%;
  }

  .text_container {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    align-items: flex-end;
    align-content: center;
    justify-content: space-between;
    background-color: #160d1c;
    padding: 7%;
    cursor: default;
  }

  .scrollable_area_left {
    width: 40vw;
    height: 85vh;
    overflow: scroll;
    color: #908f73;
    scroll-behavior: smooth;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .scrollable_area_right {
    width: 40vw;
    height: 85vh;
    overflow: scroll;
    color: #e7930d;
    scroll-behavior: smooth;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .fade_in {
    position: sticky;
    top: 0;

    display: block;

    width: 100%;
    height: 50px;

    background-image: linear-gradient(to top, #160d1c00, #160d1c 100%);
  }

  .fade_out {
    position: sticky;
    bottom: -1px;

    display: block;

    width: 100%;
    height: 50px;

    background-image: linear-gradient(to bottom, #160d1c00, #160d1c 100%);
  }

  @media only screen and (max-width: 768px) {
    .text_container {
      flex-direction: column;
      justify-content: center;
    }

    .scrollable_text {
      font-size: 1.2em;
    }

    .scrollable_area_left {
      height: 40vh;
      width: 90vw;
    }
    .scrollable_area_right {
      height: 40vh;
      width: 90vw;
    }

    .fade_in {
      width: 100%;
      height: 25px;
    }

    .fade_out {
      width: 100%;
      height: 25px;
    }
  }

  .scrollable_area_left::-webkit-scrollbar {
    display: none;
  }
  .scrollable_area_right::-webkit-scrollbar {
    display: none;
  }
</style>
