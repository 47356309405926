<script>
    const images = [
        "chiara_1.jpg",
        "chiara_2.jpg",
        "chiara_3.jpg",
        "chiara_4.jpg",
        "chiara_5.jpg",
        "chiara_6.jpg",
        "chiara_7.jpg",
    ];

    let current_index = 0;
</script>

<style>
    .image-container {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .image-container img {
        max-width: 100vw;
        max-height: 100vh;
    }
</style>

<div
    class="image-container"
    on:click={() => (current_index = (current_index + 1) % images.length)}>
    <img src={images[current_index]} alt="" />
</div>
