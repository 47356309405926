<script>
  export let link;
</script>

<!-- <h1>IFRAME HIER</h1> -->
<!-- svelte-ignore a11y-missing-attribute -->
<div class="video-container">
  <iframe src={link} frameborder="0" allow="fullscreen; picture-in-picture" />
</div>

<style>
  .video-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  iframe {
    width: 60vw;
    height: 80vh;

    z-index: 80;
    opacity: 100;
  }
  @media only screen and (max-width: 768px) {
    iframe {
      width: 100vw;
    }
  }
</style>
