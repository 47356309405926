<script>
  export let showWiki;
  export let currentArticle;
  export let closeArtistPage;
  export let setCurrentArticle;

  import WikiWindow from "../components/WikiWindow.svelte";
  import articles from "../assets/articles.js";
  import GltfViewer from "./gltfViewer.svelte";

  const objectPaths = articles.map((a) => a.object);
</script>

{#if showWiki && currentArticle}
  <WikiWindow article={currentArticle} {closeArtistPage} />
{:else}
  <GltfViewer paths={objectPaths} {setCurrentArticle} />
{/if}
