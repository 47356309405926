export default {
  title: "Simulacrum 24/7",
  name: "Frappant",
  abstract: `Simulacrum 24/7 ist ein interdisziplinäres, medien- und raumübergreifendes Gruppenprojekt, das anhand von artifiziellen und simulierten Materialitäten sowie synthetischen Texturen eine symbiotische Interaktion des neu entstandenen digitalen »[4. Raums](https://dervierteraum.org/)« der [Frappant Galerie](https://frappant.org/galerie/) mit ihren bereits bestehenden haptischen Räumlichkeiten erforscht und hinterfragt.
  <br>Die Verbindung von digitalem und haptischem Raum äußert sich in der Idee des [Mockups](https://de.wikipedia.org/wiki/Vorf%C3%BChrmodell) als ultimative [IRL](https://de.wikipedia.org/wiki/Real_Life)-[URL](https://de.wikipedia.org/wiki/Uniform_Resource_Locator)-Verschmelzung. `,
  sections: [
    {
      classname: "mockup",
      title: "Mockup",
      text: `Mockups sind auf einen Teilaspekt ausgestaltete Anschauungsobjekte, die eine Idee oder einen Prozess visuell erfahrbar machen. Der Akt des skizzenhaften Vorstellens eines in der Zukunft liegenden Umstands oder einer Verdinglichung macht das Mockup zu einer halb gewissen Prophezeiung und treibt spekulative Gedanken voran. 
      <br>Als spekulatives Objekt mit klarer, aber noch nicht abschließend definierter Funktion wird es in dieser Ausstellung selbst zum autonomen Kunstwerk, das keinen Start- und keinen Endpunkt kennt und in seiner fluidität die Grenzen von Zeit und Raum neu verhandelt. Das Mockup als Fiktion ersetzt das eigentliche Produkt, während es sich als gleichzeitige Zukunftsfantasie und Realitätsnachahmung stets zwischen [Realität](https://de.wikipedia.org/wiki/Realit%C3%A4t) und [Fiktion](https://de.wikipedia.org/wiki/Fiktion), Funktion und [Spekulation](https://de.wikipedia.org/wiki/Spekulation_(Philosophie)) bewegt.`,
      asset: {
        caption: "Der Vierte Raum",
        type: "img",
        src: "qrcode.svg",
      },
    },
    {
      classname: "description",
      title: "Beschreibung",
      text: `Die vier Räume werden in einem gegenseitigen Wechselspiel gestaltet und ergänzt. So werden sowohl die drei analogen als auch der vierte, digitale Raum zu interaktiven Oberflächen, die den Grundriss für eine online und AFK (Away From Keyboard) Interaktion zwischen den Besucher:innen, User:innen und künstlerischen Positionen bilden. 
      <br><br>Sowohl für den analogen als auch für den digitalen Raum eigens geschaffene Arbeiten treten in einen Dialog, der die Grenzen und Möglichkeiten der unterschiedlichen Welten erkennt und mit ihnen arbeitet. 
      <br><br>Simulacrum 24/7 lädt die Besuchenden dazu ein, in das fibröse Kontinuum von Realität und Wahrnehmung einzutauchen und ihre Perspektive auf die teils skotomisierten Zusammenhänge von [Digital](https://de.wikipedia.org/wiki/Digitale_Welt) und Analog neu zu ordnen. Von außenstehenden Betrachtenden werden sie aufgefordert, eine aktive und souveräne Rolle in der epistemologischen Reise durch die vier Ausstellungsräume einzunehmen. Die Werke *Obstacles of the Remaining* und *Fatigue* bilden eine fragmentierte, auditiv-skulpturale Landschaft, die in Form von erschlafften Hüllen hypothetisch dreidimensionaler Körper der Passage eine haptische Gestalt verleiht. In einem semantisch stimulierten Weiterklang schaffen die Arbeiten *CC:Lashes* und *Emotional Space* einen intimen, umhüllenden Besinnungsraum, der die Pilgernden zu einer sinnlichen Selbstreflexion gestelzter Alltagsrituale beruft und ihnen ein synkretistisches Kit als Schutzvehikel bereitstellt, um sie in die [Immersion](https://de.wikipedia.org/wiki/Immersion) zu begleiten. Auf ihrer Suche nach real Reality empfangen die Raumübergreifenden Installationen *6/6 Glasses* und *Intensivsimulation* die hüllenlosen Reisenden in die neu entstandene Dimension, die die Grenzen zwischen analog und digital endgültig auflöst und somit die Passage in den vierten Raum stabilisiert. 
      <br><br>Simulacrum 24/7 versucht eine Vorbildfunktion für zukünftige synergetische Ausstellungsformen einzunehmen. Die Ausstellung selbst wird zum Mockup.
      `,
      asset: {
        caption: "Simulacrum 24/7 Ausstellungsplakat",
        type: "img",
        src: "plakat.png",
      },
    },
    {
      classname: "working-group",
      title: "Mitwirkende",
      text: `Stefano Dealessandri, Chiara Mizaikoff  & Charlotte Hafke, Lukas Besenfelder, Laurin & Jonas Schuh, Vito Schöneberger, Rebecca Soehlke, Godje Loof, Julia Löffler, Lucia Girardet, Gina Bartzok`,
    },
    {
      classname: "sponsoring",
      title: "Unterstützung",
      text: `Dieses Projekt wird aus Mitteln von der [ZEIT-Stiftung Ebelin und Gerd Bucerius](https://de.wikipedia.org/wiki/Zeit-Stiftung) sowie KULTUR.GEMEINSCHAFTEN – Förderprogramm für digitale Content-Produktion von Kultureinrichtungen und [NEUSTART KULTUR](https://de.wikipedia.org/wiki/Neustart_Kultur) – Programm 2 gefördert. Für die freundliche Unterstützung durch die Beauftragte der Bundesregierung für Kultur und Medien, die Kulturstiftung der Länder, den Bundesverband Soziokultur e.V., die Behörde für Kultur und Medien Hamburg sowie den Frappant e.V. bedanken wir uns.`,
    },
    {
      classname: "links",
      title: "Weiterführende Links",
      text: `Laurin Schuh [@laurinschuh](https://www.instagram.com/laurinschuh/)
      <br>Jonas Schuh [@rtqu](https://www.instagram.com/rtqu/)
      <br>Vito [@murderkinq](https://www.instagram.com/murderkinq/)
      <br>Rebecca Soehlke [@rebeccuxs](https://www.instagram.com/rebeccuxs/)
      <br>Charlotte Hafke [@charlotte_ha](https://www.instagram.com/charlotte_ha/)
      <br>Chiara Mizaikoff [@chiara.mzkf](https://www.instagram.com/chiara.mzkf/)
      <br>Lukas Besenfelder [@bukaslesenfelder](https://www.instagram.com/bukaslesenfelder/)
      <br>Stefano Dealessandri [@stefanodealessandri](https://www.instagram.com/stefanodealessandri/)
      <br>Godje Loof [@godjeloof](https://www.instagram.com/odjeloof/)
      <br>Lucia Girardet [@lucia.girardet](https://www.instagram.com/lucia.girardet/)
      <br>Julia Löffler [@julia.loeff](https://www.instagram.com/@julia.loeff/)
      <br>Gina Bartzok [@_th.gb](https://www.instagram.com/_th.gb/)
      `,
    },
    {
      classname: "imprint",
      title: "Impressum",
      text: `Das Impressum dieser Seite ist [hier](https://frappant.org/kontakt/) einsehbar.`,
    },
  ],
  artwork: {
    type: "img",
    src: "plakat.png",
  },
};
