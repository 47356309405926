<script>
  export let enterFn;

  let scale = 0;
  function animateDisplacement(e) {
    const d =
      Math.hypot(
        e.clientX - window.innerWidth / 2,
        e.clientY - window.innerHeight / 2
      ) / Math.max(window.innerWidth, window.innerHeight);

    scale = 1 - Math.pow(1 - d, 3);
    scale *= 80;
  }
</script>

<svelte:window on:mousemove={animateDisplacement} />
<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<svg width="100vw" height="100vh">
  <defs>
    <filter
      id="displacement_filter"
      x="-20%"
      y="-20%"
      width="140%"
      height="140%"
      filterUnits="objectBoundingBox"
      primitiveUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
      transform="translate(0, 0)"
    >
      <feDisplacementMap
        in="SourceGraphic"
        in2="SourceGraphic"
        {scale}
        xChannelSelector="R"
        yChannelSelector="G"
        x="0"
        y="0"
        width="100%"
        height="100%"
        result="displacementMap"
      />
      <feComposite
        in="displacementMap"
        in2="SourceGraphic"
        operator="atop"
        x="0%"
        y="0%"
        width="100%"
        height="100%"
        result="composite"
      />
    </filter>
  </defs>

  <image
    filter="url(#displacement_filter)"
    x="0"
    y="0"
    width="100%"
    height="100%"
    xlink:href="splash-bg-klein.jpg"
    preserveAspectRatio="xMidYMid slice"
  />
</svg>

<div class="enter-container">
  <div class="big_button" on:click={enterFn}>[enter]</div>
</div>

<style>
  /* .image_unfiltered { 
    width: 100vw;
    min-height: 100%;
    margin: 0;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -10;
    transform: translate(0, 0);
  } */

  .big_button {
    cursor: pointer;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 200px;
    line-height: 154.99%;
    color: #e9e9e9;
    mix-blend-mode: multiply;
    text-shadow: 0px 3px 7px rgba(56, 56, 56, 0.61);
  }
  .big_button:hover {
    font-style: italic;
  }

  .enter-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 768px) {
    .big_button {
      font-size: 80px;
    }
  }

  /* @media (min-width: 769px) {
    .image_unfiltered {
      filter: url(#displacement_filter);
    }
  }

  @media only screen and (max-width: 768px) {
    .image_unfiltered {
      height: 100vh;
      width: auto;
    } 
  }*/
</style>
