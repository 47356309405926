<script>
  export let close;

  import WikiWindow from "./WikiWindow.svelte";
  import info from "../assets/info";
</script>

<div class="info_page">
  <WikiWindow article={info} closeArtistPage={close} />
</div>

<style>
  .info_page {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>
