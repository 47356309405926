<script>
  export let src;
</script>

<!-- svelte-ignore a11y-missing-attribute -->
<div class="img-container">
  <img {src} />
</div>

<style>
  .img-container {
    width: 100vw;
    height: 100vh;

    z-index: 80;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 100;
    left: 0;
    top: 0;
  }

  img {
    height: 100vh;
  }
  @media only screen and (max-width: 768px) {
    img {
      width: 90vw;
      height: auto;
    }
  }
</style>
