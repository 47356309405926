<script>
  export let section;
  export let openFullscreen;

  import SvelteMarkdown from "svelte-markdown";
  import ImageIcon from "../assets/image_icon.svg";
  import WikiCustomLinks from "./WikiCustomLinks.svelte";

  const renderers = {
    link: WikiCustomLinks,
  };
</script>

<div class={`section ${section.classname}`}>
  <h2>{section.title}</h2>
  <div class="text-image-content">
    <div class="text">
      <p class="font-medium">
        <SvelteMarkdown source={section.text} {renderers} />
      </p>
    </div>
    {#if section.asset}
      {#if section.asset.type === "img"}
        <div class="image-box" on:click={openFullscreen}>
          <img loading="lazy" src={section.asset.src} alt="Kunstrasen" />
          <div class="image-caption">
            <span class="font-small">
              {section.asset.caption}
            </span>
            <ImageIcon />
          </div>
        </div>
      {/if}
    {/if}
  </div>
</div>

<style>
  .section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    gap: 15px;
    margin-top: 20px;
  }

  h2 {
    font-family: "Times New Roman";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    border-bottom: 1px solid #a2a9b1;
    align-self: stretch;
    margin: 0;
  }

  .text {
    max-width: 100%;
  }

  :global(.font-medium) {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 154.99%;
    margin: 0;
    hyphens: auto;
  }

  :global(sup) {
    line-height: 100%;
    color: #0e49aa;
  }

  .section {
    display: flex;
    flex-direction: column;
  }

  .text-image-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  @media only screen and (max-width: 768px) {
    .text-image-content {
      flex-direction: column;
      max-width: 100%;
    }
  }

  .image-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 5px 10px;
    gap: 10px;
    min-width: 300px;
    margin: 0 14px;
    cursor: pointer;

    background: #f8f9fa;
    border: 1px solid #c8ccd1;
  }

  @media only screen and (max-width: 768px) {
    .image-box {
      margin: 18px 0;
      max-width: 100%;
    }
  }

  .image-box img {
    width: 100%;
    background: #ffffff;
    border: 1px solid #c8ccd1;
  }

  .image-caption {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  :global(ul) {
    padding-inline-start: 10px;
  }
</style>
