<script>
    import SvelteMarkdown from "svelte-markdown";
    import WikiCustomLinks from "./WikiCustomLinks.svelte";

    export let title;
    export let abstract;
    export let closeArtistPage;

    const renderers = {
        link: WikiCustomLinks,
    };
</script>

<style>
    h1 {
        font-family: "Times New Roman";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        margin-bottom: 0.25em;
        line-height: 1.3;
        border-bottom: 1px solid #a2a9b1;
        align-self: stretch;
        margin: 0;
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        gap: 5px;
    }

    :global(.font-small) {
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
    }
</style>

<div class="header">
    <h1>{title}</h1>
    <!-- svelte-ignore a11y-missing-attribute -->
    <a on:click={closeArtistPage} class="font-small">Zurück zur Ausstellung</a>
    <p class="font-medium">
        <SvelteMarkdown {renderers} source={abstract} />
    </p>
</div>
