<script>
  import { onMount } from "svelte";
  import * as THREE from "three";
  import { GLTFLoader } from "../lib/GLTFLoader";
  import { OrbitControls } from "../lib/OrbitControls";
  import { RGBELoader } from "../lib/RGBELoader";
  import articles from "../assets/articles.js";

  export let paths;
  export let setCurrentArticle;

  const dpr = window.devicePixelRatio;
  let width = window.innerWidth / dpr,
    height = window.innerHeight / dpr,
    canvas,
    scene,
    camera,
    renderer,
    controls,
    raycaster = new THREE.Raycaster(),
    pointer = new THREE.Vector2(),
    loader = new GLTFLoader(),
    selectedObj = null,
    artistName = "",
    title = "",
    scrollstep = 0.001,
    sign = 1;

  function loadObjectFromPath(path, id) {
    loader.load(path, (gltf) => {
      // position the object randomly in the scene
      const object = gltf.scene;
      let [a, b] = [4, 2];
      let scale = 1.1;
      if (window.innerWidth < 800) {
        scale = 0.8;
      }
      object.position.x = Math.random() * a - b;
      object.position.y = Math.random() * a - b;
      object.position.z = Math.random() * a - b;

      object.rotation.x = Math.random() * 2 * Math.PI;
      object.rotation.y = Math.random() * 2 * Math.PI;
      object.rotation.z = Math.random() * 2 * Math.PI;

      object.scale.x *= scale;
      object.scale.y *= scale;
      object.scale.z *= scale;

      object.traverse((child) => {
        if (child.isMesh) {
          child.userData.frappantId = id;
        }
      });

      scene.add(object);
    });
  }

  onMount(() => {
    scene = new THREE.Scene();

    renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      alpha: true,
    });
    renderer.setSize(width, height, false);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1;
    renderer.outputEncoding = THREE.sRGBEncoding;

    camera = new THREE.PerspectiveCamera(15, width / height, 0.1, 1000);

    controls = new OrbitControls(camera, renderer.domElement);
    controls.enableZoom = false;
    controls.enablePan = false;

    new RGBELoader().load("studio_small_08_1k.hdr", (texture) => {
      texture.mapping = THREE.EquirectangularReflectionMapping;
      scene.environment = texture;

      let id = 0;
      for (let path of paths) {
        loadObjectFromPath(path, id);
        id++;
      }
    });

    const light = new THREE.AmbientLight(0x404040); // soft white light
    scene.add(light);

    camera.position.z = 18;
    camera.position.y = 0.5;
    animate();
  });

  function handleIntersection() {
    raycaster.setFromCamera(pointer, camera);
    const intersects = raycaster.intersectObjects(scene.children);
    if (intersects.length > 0) {
      document.body.style.cursor = "pointer";
      const intersected = intersects[0].object;
      if (intersected !== selectedObj) {
        if (selectedObj) {
          selectedObj.material.emissive.setHex(0x000000);
        }
        selectedObj = intersected;
        selectedObj.material.emissive.setHex(0x424242);

        const index = selectedObj.userData.frappantId;
        const article = articles[index];
        artistName = article.name;
        title = article.title;

        //     document.body.style.backgroundImage = `url(${article.bgImage})`;
      }
    } else {
      document.body.style.cursor = "move";
      if (selectedObj) {
        selectedObj.material.emissive.setHex(0x000000);
      }
      selectedObj = null;
      // document.body.style.backgroundImage = "";
      artistName = "";
      title = "";
    }
  }

  function rotateObjects() {
    if (scene && scene.children && scene.children.length > 1) {
      scene.traverse((child) => {
        if (child.isMesh) {
          // child.rotation.x += sign * scrollstep;
          child.rotation.y += sign * scrollstep;
          // child.rotation.z += sign * scrollstep;

          // child.position.x = Math.sin(child.rotation.x) * 2;
          child.position.y = Math.sin(child.rotation.y) * 2;
          // child.position.z = Math.cos(child.rotation.z) * 2;
        }
      });
    }
  }

  function animate() {
    requestAnimationFrame(animate);
    rotateObjects();
    camera.lookAt(scene.position);
    handleIntersection();
    renderer.render(scene, camera);

    scrollstep = Math.max(0.001, scrollstep - 0.01);
  }

  window.addEventListener("resize", () => {
    width = window.innerWidth / dpr;
    height = window.innerHeight / dpr;
    renderer.setSize(width, height);
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
  });

  window.addEventListener("pointermove", (event) => {
    pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
  });

  function handleWheel(e) {
    if (e.wheelDelta < 0) {
      sign = -1;
    } else {
      sign = 1;
    }
    scrollstep = Math.min(1, scrollstep + 0.01);
  }
  document.addEventListener("wheel", handleWheel);

  function handleClick() {
    if (selectedObj) {
      selectedObj.material.emissive.setHex(0x00ff00);
      const index = selectedObj.userData.frappantId;
      const article = articles[index];
      setCurrentArticle(article);
    }
  }
</script>

<div>
  {#if artistName && title}
    <!-- svelte-ignore a11y-missing-attribute -->
    <div class="artist-container">
      <div class="artist-info">
        <p style="border-bottom: 1.5px solid #000000;">{artistName}</p>
        <p style="font-style: italic">{title}</p>
      </div>
    </div>
  {/if}
  <canvas on:click={handleClick} bind:this={canvas} {width} {height} />
</div>

<style>
  .artist-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    z-index: -1;
  }

  .artist-info {
    display: flex;
    flex-direction: column;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    border: 1.5px solid #000000;
    width: 320px;
    margin: 20px;
  }
  .artist-info p {
    padding: 6px 20px 6px 10px;
  }

  @media only screen and (max-width: 768px) {
    .artist-info {
      width: 65vw;
    }
  }

  :global(body) {
    background-image: url(../check_bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
</style>
