<script>
</script>

<div class="stefanos_work">
  <video id="videoBG" poster="images/start.png" autoplay muted loop>
    <source src="stefano_bg_video.mp4" type="video/mp4" />
  </video>
  <h1>Kunstrasen ≠ Kunstrasen</h1>
  <div class="interacty_padding">
    <div class="interacty_wrapper">
      <iframe
        title="Memory Game"
        style="border: none; width: 100%; height: 100%;"
        src="https://p.interacty.me/a11c86b0a68e59a2/iframe.html"
      />
    </div>
  </div>
</div>

<style>
  .stefanos_work {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .interacty_padding {
    position: fixed;
    width: 80%;
    height: 80vh;
    align-self: center;
    position: static;
    padding: 0 0 0 0;
    display: flex;
    margin: 0 auto;
  }

  #videoBG {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
  }
  h1 {
    text-align: center;
    font-size: 6vw;
    font-family: Arial, Helvetica, sans-serif;
    color: #ffffff;
    text-shadow: 3px, blue;
    -webkit-text-stroke-width: 0.2vw;
    -webkit-text-stroke-color: rgb(0, 13, 255);
    text-shadow: 0px 0px 20px greenyellow;
  }

  .interacty_wrapper {
    position: static;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    margin: 0 auto;
  }

  @media only screen and (max-width: 768px) {
    h1 {
      position: absolute;
      top: 8vh;
      text-align: center;
      width: 100vw;
    }
    .interacty_padding {
      top: 20vh;
      position: fixed;
      width: 100vw;
      height: 80vh;
    }
    .interacty_wrapper {
      width: 100vw;
      height: 80vh;
    }
    iframe {
      bottom: 20px;
    }
  }
</style>
