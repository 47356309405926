<script>
  import ImagesSvgFilter from "../components/ImagesSvgFilter.svelte";
  import About from "../components/About.svelte";
  import FrappantRoom from "../components/frappantRoom.svelte";

  let aboutIsVisible = false;
  let hasEntered = false;
  let showWiki = false;
  let currentArticle = null;

  function enterRoom() {
    hasEntered = true;
  }

  function showAbout() {
    aboutIsVisible = !aboutIsVisible;
  }

  function setCurrentArticle(article) {
    currentArticle = article;
    showWiki = true;
  }

  function closeArtistPage() {
    aboutIsVisible = false;
    showWiki = false;
    currentArticle = null;
  }
</script>

<div class={hasEntered ? "hidden" : "entered"}>
  <ImagesSvgFilter enterFn={enterRoom} />
</div>
<div class={hasEntered ? "entered" : "hidden"}>
  <FrappantRoom
    {showWiki}
    {currentArticle}
    {setCurrentArticle}
    {closeArtistPage}
  />
</div>

{#if aboutIsVisible}
  <About close={showAbout} />
{/if}
<div class="nav">
  {#if showWiki || aboutIsVisible}
    <button on:click={closeArtistPage}>[zurück]</button>
  {/if}
  {#if !aboutIsVisible}
    <button on:click={showAbout}>[Info]</button>
  {/if}
</div>

<style>
  .entered {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .hidden {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    display: none;
  }
</style>
